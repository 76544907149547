var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-dialog" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          !_vm.isBatch
            ? _c(
                "el-tab-pane",
                { attrs: { label: "报警详情", name: "first" } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 40 } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("span", [_vm._v("报警详情")]),
                        _c("div", { staticClass: "information" }, [
                          _c("div", { staticClass: "head" }, [
                            _c("p", [
                              _c("span", [_vm._v("车牌号：")]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.detailForm.alarmBaseVO.cph))
                              ])
                            ]),
                            _c("p", [
                              _c("span", [_vm._v("所属车队：")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.detailForm.alarmBaseVO.companyName)
                                )
                              ])
                            ]),
                            _c("p", { staticClass: "noWrap" }, [
                              _c("span", [_vm._v("报警类型：")]),
                              _c("span", { staticClass: "sign" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.computedAlarmType(
                                        _vm.detailForm.alarmBaseVO.alarmName,
                                        _vm.detailForm.alarmBaseVO.alarmType
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]),
                            _c("p", [
                              _c("span", [_vm._v("报警等级：")]),
                              _c("span", { staticClass: "sign" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.alarmLevelObj[
                                        Number(
                                          _vm.detailForm.alarmBaseVO.alarmLevel
                                        )
                                      ]
                                    ) +
                                    " "
                                )
                              ])
                            ])
                          ]),
                          _c(
                            "div",
                            { staticClass: "body" },
                            [
                              _c(
                                "el-table",
                                {
                                  ref: "tableName",
                                  staticClass: "no-blank alarm-table",
                                  attrs: {
                                    data: _vm.detailForm.alarmDetailVoList,
                                    height: "calc(45vh - 118px)"
                                  },
                                  on: { "row-click": _vm.RowDetailFun }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { type: "index", label: "序号" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "alarmSignTime",
                                      label: "报警时间",
                                      "show-overflow-tooltip": true
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "velocity",
                                      label: "速度/限速（km/h)",
                                      width: "160"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.velocity
                                                      ? scope.row.velocity
                                                      : "-"
                                                  ) +
                                                    "/" +
                                                    _vm._s(
                                                      _vm.detailForm.alarmBaseVO
                                                        .limitValue
                                                        ? _vm.detailForm
                                                            .alarmBaseVO
                                                            .limitValue
                                                        : "-"
                                                    )
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      154667748
                                    )
                                  }),
                                  _vm.detailForm.alarmBaseVO.alarmType ===
                                    2012 ||
                                  _vm.detailForm.alarmBaseVO.alarmType === 2051
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "超速比例",
                                          width: "80"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "filterProportion"
                                                        )(
                                                          scope.row.velocity,
                                                          _vm.detailForm
                                                            .alarmBaseVO
                                                            .limitValue
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2756181470
                                        )
                                      })
                                    : _vm._e(),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "vehStatus",
                                      label: "车辆状态",
                                      width: "180",
                                      "show-overflow-tooltip": true
                                    }
                                  }),
                                  _vm.detailForm.alarmBaseVO.alarmType ===
                                    2051 ||
                                  _vm.detailForm.alarmBaseVO.alarmType ===
                                    2052 ||
                                  _vm.detailForm.alarmBaseVO.alarmType ===
                                    2054 ||
                                  _vm.detailForm.alarmBaseVO.alarmType === 2053
                                    ? _c("el-table-column", {
                                        attrs: {
                                          prop: "alarmContent",
                                          label: "报警内容",
                                          width: "180",
                                          "show-overflow-tooltip": true
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("p", { staticClass: "title" }, [_vm._v("轨迹")]),
                        _c(
                          "div",
                          { staticClass: "map-style" },
                          [
                            _c("searchMap", {
                              ref: "searchMap",
                              attrs: { locatePointShow: _vm.locatePointShow }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 40 } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "p",
                          { staticClass: "title" },
                          [
                            _vm._v(" 报警附件 "),
                            _vm.fileForm.length > 0
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "16px" },
                                    attrs: { type: "primary", size: "small" },
                                    on: { click: _vm.downLoadFile }
                                  },
                                  [_vm._v("下载附件")]
                                )
                              : _vm._e(),
                            _vm.fileForm.length < 1 &&
                            _vm.detailData &&
                            _vm.detailData.alarmLevel == 2 &&
                            _vm.detailData.alarmSource == 2 &&
                            _vm.locatePointShow
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "16px" },
                                    attrs: { type: "primary", size: "small" },
                                    on: { click: _vm.orderAttachment }
                                  },
                                  [_vm._v("获取附件")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.fileForm.length < 1
                          ? _c("div", { staticClass: "null-tips" }, [
                              _vm._v("暂无附件")
                            ])
                          : _c(
                              "div",
                              { staticStyle: { height: "calc(60vh - 144px)" } },
                              [
                                _vm.videoPlayArr.length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "mediaStyle" },
                                      [
                                        _c(
                                          "el-carousel",
                                          {
                                            attrs: {
                                              interval: 10000,
                                              "indicator-position": "none",
                                              trigger: "click"
                                            }
                                          },
                                          _vm._l(_vm.videoPlayArr, function(
                                            item
                                          ) {
                                            return _c(
                                              "el-carousel-item",
                                              { key: item.time },
                                              [
                                                _c("video-player", {
                                                  ref: "videoPlayer",
                                                  refInFor: true,
                                                  staticClass:
                                                    "video-player vjs-custom-skin",
                                                  attrs: {
                                                    playsinline: true,
                                                    options: item.fileNo
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._l(_vm.imgShowArr, function(item) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.time,
                                      staticClass: "mediaStyle"
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: item.alarmFilePath,
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("el-tab-pane", { attrs: { label: "报警处理", name: "second" } }, [
            _c("div", { staticClass: "alarm-dia" }, [
              !_vm.fileDialog
                ? _c("div", [
                    !_vm.isBatch
                      ? _c("div", { staticClass: "scroll" }, [
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    (_vm.detailData &&
                                      _vm.detailData.disposeStatus !== 2) ||
                                    (_vm.detailForm.disposeAlarmDataVoList &&
                                      _vm.detailForm.disposeAlarmDataVoList
                                        .length !== 0),
                                  expression:
                                    "\n          (detailData && detailData.disposeStatus !== 2) ||\n          detailForm.disposeAlarmDataVoList&&detailForm.disposeAlarmDataVoList.length !== 0\n        "
                                }
                              ],
                              staticClass: "title"
                            },
                            [_vm._v("处理记录")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    (_vm.detailData &&
                                      _vm.detailData.disposeStatus !== 2) ||
                                    (_vm.detailForm.disposeAlarmDataVoList &&
                                      _vm.detailForm.disposeAlarmDataVoList
                                        .length !== 0),
                                  expression:
                                    "\n          (detailData && detailData.disposeStatus !== 2) ||\n          detailForm.disposeAlarmDataVoList&&detailForm.disposeAlarmDataVoList.length !== 0\n        "
                                }
                              ],
                              staticClass: "record"
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "no-blank",
                                  attrs: {
                                    data: _vm.detailForm.disposeAlarmDataVoList,
                                    "max-height": "146px"
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { type: "index", label: "序号" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "operator",
                                      label: "处理人姓名"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "disposeTime",
                                      label: "处理时间",
                                      width: "180"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "disposeType",
                                      label: "处理类型"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.disposeTypeObj[
                                                      Number(
                                                        scope.row.disposeType
                                                      )
                                                    ]
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      4220730910
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "disposeWay",
                                      label: "处理方式"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.disposeWayObj[
                                                      Number(
                                                        scope.row.disposeWay
                                                      )
                                                    ]
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1059063358
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "disposeDescription",
                                      label: "处理内容",
                                      "show-overflow-tooltip": true
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.detailData && _vm.detailData.disposeStatus !== 1
                      ? _c("div", { staticClass: "fixed" }, [
                          !_vm.isBatch
                            ? _c("p", { staticClass: "title" }, [
                                _vm._v("报警处理")
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "deal" },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "alarmForm",
                                  staticClass: "demo-form deal-form",
                                  attrs: {
                                    inline: true,
                                    model: _vm.form,
                                    "label-width": "110px",
                                    "label-position": "left",
                                    rules: _vm.rules
                                  }
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        label: "报警判定：",
                                        prop: "alarmConfirm"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "请选择" },
                                          on: { change: _vm.changeConfirm },
                                          model: {
                                            value: _vm.form.alarmConfirm,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "alarmConfirm",
                                                $$v
                                              )
                                            },
                                            expression: "form.alarmConfirm"
                                          }
                                        },
                                        _vm._l(_vm.alarmConfirmList, function(
                                          item
                                        ) {
                                          return _c("el-option", {
                                            key: item.dictCode,
                                            attrs: {
                                              label: item.dictValue,
                                              value: item.dictCode
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.form.alarmConfirm === "1"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "处理方式：",
                                            prop: "disposeWay"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { placeholder: "请选择" },
                                              on: { change: _vm.changeWay },
                                              model: {
                                                value: _vm.form.disposeWay,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "disposeWay",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.disposeWay"
                                              }
                                            },
                                            _vm._l(_vm.disposeWayList, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.dictCode,
                                                attrs: {
                                                  label: item.dictValue,
                                                  value: item.dictCode
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.form.disposeWay === "1" &&
                                  _vm.form.alarmConfirm === "1"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "文本模板",
                                            prop: "textTemplate"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { placeholder: "请选择" },
                                              on: {
                                                change: _vm.selectTextTemplate
                                              },
                                              model: {
                                                value: _vm.form.textTemplate,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "textTemplate",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.textTemplate"
                                              }
                                            },
                                            _vm._l(_vm.templateList, function(
                                              item,
                                              key
                                            ) {
                                              return _c("el-option", {
                                                key: key,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.id
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.form.disposeWay === "3"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "电话号码：",
                                            prop: "phone"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入手机号"
                                            },
                                            model: {
                                              value: _vm.form.phone,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "phone",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression: "form.phone"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("el-form-item"),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "处理描述：",
                                        prop: "disposeDescription"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          placeholder: "请输入***"
                                        },
                                        model: {
                                          value: _vm.form.disposeDescription,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "disposeDescription",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "form.disposeDescription"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }