<template>
  <div class="alarm-dialog">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="报警详情" name="first" v-if="!isBatch">
        <el-row :gutter="40">
          <el-col :span="12">
            <span>报警详情</span>
            <div class="information">
              <div class="head">
                <p>
                  <span>车牌号：</span>
                  <span>{{ detailForm.alarmBaseVO.cph }}</span>
                </p>
                <p>
                  <span>所属车队：</span>
                  <span>{{ detailForm.alarmBaseVO.companyName }}</span>
                </p>
                <p class="noWrap">
                  <span>报警类型：</span>
                  <span class="sign">
                    {{ computedAlarmType(detailForm.alarmBaseVO.alarmName,detailForm.alarmBaseVO.alarmType)
                    }}
                  </span>
                </p>
                <p>
                  <span>报警等级：</span>
                  <span class="sign">
                    {{
                    alarmLevelObj[Number(detailForm.alarmBaseVO.alarmLevel)]
                    }}
                  </span>
                </p>
              </div>
              <div class="body">
                <el-table
                  :data="detailForm.alarmDetailVoList"
                  height="calc(45vh - 118px)"
                  class="no-blank alarm-table"
                  ref="tableName"
                  @row-click="RowDetailFun"
                >
                  <el-table-column type="index" label="序号"></el-table-column>
                  <el-table-column prop="alarmSignTime" label="报警时间" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="velocity" label="速度/限速（km/h)" width="160">
                    <template slot-scope="scope">
                      <span>{{scope.row.velocity?scope.row.velocity:'-'}}/{{detailForm.alarmBaseVO.limitValue?detailForm.alarmBaseVO.limitValue:'-'}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-if="detailForm.alarmBaseVO.alarmType === 2012||detailForm.alarmBaseVO.alarmType === 2051"
                    label="超速比例"
                    width="80"
                  >
                    <template slot-scope="scope">
                      <span>{{scope.row.velocity | filterProportion(detailForm.alarmBaseVO.limitValue)}}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="vehStatus"
                    label="车辆状态"
                    width="180"
                    :show-overflow-tooltip="true"
                  ></el-table-column>
                  <el-table-column
                    prop="alarmContent"
                    label="报警内容"
                    v-if="detailForm.alarmBaseVO.alarmType === 2051||detailForm.alarmBaseVO.alarmType === 2052 || detailForm.alarmBaseVO.alarmType === 2054 ||detailForm.alarmBaseVO.alarmType === 2053"
                    width="180"
                    :show-overflow-tooltip="true"
                  ></el-table-column>
                </el-table>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <p class="title">轨迹</p>
            <div class="map-style">
              <searchMap ref="searchMap" :locatePointShow="locatePointShow" />
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <p class="title">
              报警附件
              <el-button
                type="primary"
                size="small"
                style="margin-left:16px;"
                v-if="fileForm.length>0"
                @click="downLoadFile"
              >下载附件</el-button>
              <!-- 二级 终端产生的报警才有附件 -->
          <el-button
            type="primary"
            size="small"
            style="margin-left:16px;"
            v-if="fileForm.length<1&&detailData&&detailData.alarmLevel==2&&detailData.alarmSource==2 && locatePointShow"
            @click="orderAttachment"
          >获取附件</el-button>
            </p>
            <div v-if="fileForm.length<1" class="null-tips">暂无附件</div>
            <div v-else style="height: calc(60vh - 144px);">
              <!-- <div v-for="item in fileForm" :key="item.time" class="mediaStyle">
                <div v-if="item.alarmFileType === 0">
                  <img :src="item.alarmFilePath" alt />
                </div>
                <div v-if="item.alarmFileType === 2">
                  <video-player
                    class="video-player vjs-custom-skin"
                    ref="videoPlayer"
                    :playsinline="true"
                    :options="item.fileNo"
                  ></video-player>
                </div>
              </div>-->
              <!-- 视频采用轮播的方式 -->
              <!-- 视频 -->
              <div class="mediaStyle" v-if="videoPlayArr.length>0">
                <el-carousel :interval="10000" indicator-position="none" trigger="click">
                  <el-carousel-item v-for="item in videoPlayArr" :key="item.time">
                    <video-player
                      class="video-player vjs-custom-skin"
                      ref="videoPlayer"
                      :playsinline="true"
                      :options="item.fileNo"
                    ></video-player>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <!-- 图片 -->
              <div v-for="item in imgShowArr" :key="item.time" class="mediaStyle">
                <img :src="item.alarmFilePath" alt />
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="报警处理" name="second">
        <div class="alarm-dia">
          <div v-if="!fileDialog">
            <div class="scroll" v-if="!isBatch">
              <p
                class="title"
                v-show="
            (detailData && detailData.disposeStatus !== 2) ||
            detailForm.disposeAlarmDataVoList&&detailForm.disposeAlarmDataVoList.length !== 0
          "
              >处理记录</p>
              <div
                class="record"
                v-show="
            (detailData && detailData.disposeStatus !== 2) ||
            detailForm.disposeAlarmDataVoList&&detailForm.disposeAlarmDataVoList.length !== 0
          "
              >
                <el-table
                  :data="detailForm.disposeAlarmDataVoList"
                  max-height="146px"
                  class="no-blank"
                >
                  <el-table-column type="index" label="序号"></el-table-column>
                  <el-table-column prop="operator" label="处理人姓名"></el-table-column>
                  <el-table-column prop="disposeTime" label="处理时间" width="180"></el-table-column>
                  <el-table-column prop="disposeType" label="处理类型">
                    <template slot-scope="scope">
                      {{
                      disposeTypeObj[Number(scope.row.disposeType)]
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="disposeWay" label="处理方式">
                    <template slot-scope="scope">
                      {{
                      disposeWayObj[Number(scope.row.disposeWay)]
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="disposeDescription"
                    label="处理内容"
                    :show-overflow-tooltip="true"
                  ></el-table-column>
                </el-table>
              </div>
            </div>
            <!-- <div class="fixed"> -->
            <div class="fixed" v-if="detailData && detailData.disposeStatus !== 1">
              <p class="title" v-if="!isBatch">报警处理</p>
              <div class="deal">
                <el-form
                  :inline="true"
                  :model="form"
                  label-width="110px"
                  label-position="left"
                  class="demo-form deal-form"
                  :rules="rules"
                  ref="alarmForm"
                >
                  <el-form-item label="报警判定：" prop="alarmConfirm" style="width:100%;">
                    <el-select
                      v-model="form.alarmConfirm"
                      placeholder="请选择"
                      @change="changeConfirm"
                    >
                      <el-option
                        v-for="item in alarmConfirmList"
                        :label="item.dictValue"
                        :value="item.dictCode"
                        :key="item.dictCode"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="处理方式：" prop="disposeWay" v-if="form.alarmConfirm==='1'">
                    <el-select v-model="form.disposeWay" placeholder="请选择" @change="changeWay">
                      <el-option
                        v-for="item in disposeWayList"
                        :label="item.dictValue"
                        :value="item.dictCode"
                        :key="item.dictCode"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="文本模板"
                    prop="textTemplate"
                    v-if="form.disposeWay === '1' && form.alarmConfirm==='1'"
                  >
                    <el-select
                      v-model="form.textTemplate"
                      placeholder="请选择"
                      @change="selectTextTemplate"
                    >
                      <el-option
                        v-for="(item, key) in templateList"
                        :key="key"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="电话号码：" prop="phone" v-if="form.disposeWay === '3'">
                    <el-input v-model.trim="form.phone" placeholder="请输入手机号"></el-input>
                  </el-form-item>
                  <el-form-item></el-form-item>
                  <el-form-item label="处理描述：" prop="disposeDescription">
                    <el-input
                      type="textarea"
                      v-model.trim="form.disposeDescription"
                      placeholder="请输入***"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import {
  getAlarmConfigs,
  queryNoDisposeAlarm,
  querydetailData,
  queryAlarmFile,
  alarmTraceList,
  queryAlarmDetail,
  queryMessageTemplateList,
  handleDisposeAlarm
} from '@/api/lib/api.js'
import searchMap from '../../monitor/alarmSetting/components/map.vue'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import { checkPhone } from '@/common/utils/index.js'
export default {
  components: {
    searchMap,
    'video-player': videoPlayer
  },
  props: ['detailData', 'typeObj', 'alarmSourceObj', 'alarmLevelObj', 'disposeWayList', 'alarmConfirmList', 'disposeTypeObj', 'disposeWayObj', 'isBatch', 'alarmList','alarmAllObj'],
  data () {
    return {
      fileDialog: false,
      fileForm: [],
      activeName: 'first',
      detailForm: {
        alarmBaseVO: {
          cph: '',
          companyName: '',
          driverName: '',
          drivingLicense: '',
          alarmName: '',
          alarmLevel: ''
        },
        alarmDetailVoList: [],
        disposeAlarmDataVoList: []
      },
      alarmID: null,
      moniData: null,
      rules: {
        disposeWay: {
          required: true,
          message: '请选择处理方式',
          trigger: 'change'
        },
        textTemplate: {
          required: true,
          message: '请选择文本模板',
          trigger: 'change'
        },
        alarmConfirm: [
          { required: true, message: '请选择报警判定', trigger: 'blur' }
        ],
        disposeDescription: [
          { required: true, message: '请输入处理描述', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ]
      },
      form: {
        disposeWay: '1',
        terminalPhoneNo: '',
        textTemplate: '自定义',
        disposeDescription: '',
        phone: '',
        alarmConfirm: '',
      },
      templateList: [],
      locatePointShow: null,//报警点地图定位点
      system: sessionStorage.getItem('system').toString(),
      videoPlayArr:[],
      imgShowArr:[]
    }
  },
  filters: {
    filterProportion (item, limitValue) {
      let str = "-";
      if(limitValue && limitValue !==0) {
        const poportion = (item - limitValue) / limitValue;
        str = `${Math.round(poportion * 100)}%`
      }
      return str
    }
  },
  methods: {
    computedAlarmType(name,type){
      if(name){
        return name
      }else{
       return this.alarmAllObj[type]
      }
    },
    // 选择文本模板
    selectTextTemplate (id) {
      if (id !== '自定义' && id) {
        let element = this.templateList.find((item) => item.id === id)
        if (element) this.form.disposeDescription = element.detail
      } else {
        this.form.disposeDescription = ''
      }
    },
    //下载附件
    downLoadFile () {
      for (let i = 0; i < this.fileForm.length; i++) {
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        iframe.src = this.fileForm[i].alarmFilePath;
        document.body.appendChild(iframe);
        setTimeout(() => {
          iframe.remove();
        }, 1000);
      }
    },
    changeConfirm () {
      this.$refs.alarmForm.clearValidate()
    },
    // 修改处理方式
    changeWay () {
      this.form.textTemplate = '自定义';
      this.form.disposeDescription = '';
    },
    // 提交
    save () {
      if (this.fileDialog) {
        this.fileDialog = !this.fileDialog
        this.$emit('fileOpenDialog', this.fileDialog)
        return
      }
      if (this.detailData.disposeStatus === 1) {
        this.$emit('cancelDialog')
      } else {
        this.$refs.alarmForm.validate((valid) => {
          if (valid) {
            this.$emit("changeLoading",true);
            let disposeAlarmSendInfos = []
            if (!this.isBatch) {
              disposeAlarmSendInfos = [
                {
                  system: this.system,
                  alarmNo: this.detailForm.alarmBaseVO.alarmNo,
                  alarmId:this.detailData.id,
                  alarmIdStr:this.detailData.idStr,
                  alarmSignTime: this.detailForm.alarmBaseVO.alarmSignTime,
                  terminalPhoneNo: this.detailForm.alarmBaseVO.terminalPhoneNo
                }
              ]
            } else {
              //批量处理
              this.alarmList.forEach((alarm) => {
                disposeAlarmSendInfos.push({
                  system: this.system,
                  alarmNo: alarm.alarmNo,
                  alarmId:alarm.id,
                  alarmIdStr:alarm.idStr,
                  alarmSignTime: alarm.alarmSignTime,
                  terminalPhoneNo: alarm.terminalPhoneNo
                })
              })
            }
            const data = {
              disposeAlarmSendInfos: disposeAlarmSendInfos,
              // disposeDescription: `调度员提醒您：${this.form.disposeDescription}`,
              disposeDescription: this.form.disposeDescription,
              disposeWay: this.form.disposeWay,
              phone: this.form.phone,
              alarmConfirm: this.form.alarmConfirm
            }
            handleDisposeAlarm(data).then((res) => {
              if (res.code === 1000) {
                this.$emit('cancelDialog', res.data);
                this.$emit("changeLoading",false);
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            return false
          }
        })
      }
    },
    // 获取文本模板
    getTemplateList () {
      queryMessageTemplateList().then((res) => {
        if (res.code === 1000) {
          res.data.unshift({ name: '自定义', id: '自定义' })
          this.templateList = res.data
        } else {
          this.$message({
            type: 'error',
            showClose: true,
            message: res.msg
          })
        }
      })
    },
    //获取报警前后几分钟的轨迹
    getAlarmTrace () {
      const data = {
        alarmNo: this.detailData.alarmNo,
        // alarmSignTime: this.detailData.alarmSignTime,
        alarmSignTime: this.detailData.time,
        // alarmEndTime: this.detailData.alarmEndTime,
        alarmEndTime: this.detailData.time,
        minute: 5
      }
      alarmTraceList(data).then(res => {
        this.$refs.searchMap.onTrailPlay(res)
      })
    },
    handleClick (tab, event) {
      // console.log(tab, event);
    },
    RowDetailFun (row) {
      // setTimeout(()=>{
      this.locatePointShow = row;//地图轨迹标点
      // },2000)
      const requireData = {
        fileNo: row.fileNo,
        terminalPhoneNo: this.detailForm.alarmBaseVO.terminalPhoneNo,
        alarmSignTime: row.alarmSignTime,
        alarmEndTime: row.alarmEndTime
      }
      
      this.videoPlayArr = [];
      this.imgShowArr = [];
      if (!row.fileNo) {
        return
      }
      queryAlarmFile(requireData).then((res) => {
        if (res.code === 1000) {
          this.fileForm = []
          if (res.data.length > 0) {
            res.data.forEach((item) => {
              if (item.alarmFileType === 0) {
                this.fileForm.push(item)
                this.imgShowArr.push(item)
              } else if (item.alarmFileType === 2) {
                  let obj = {
                    playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                    autoplay: true, //如果true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 导致视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [
                      {
                        src: item.alarmFilePath, // 路径
                        // src:'http://ivi.bupt.edu.cn/hls/cctv1hd.m3u8',
                        type: 'video/mp4' // 类型
                        // type:'application/x-mpegURL'
                      }
                    ],
                    poster:
                      'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg', //你的封面地址
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                      timeDivider: true,
                      durationDisplay: true,
                      remainingTimeDisplay: false,
                      fullscreenToggle: true //全屏按钮
                    }
                  }
                  item.fileNo = obj
                  this.fileForm.unshift(item);//视频推到数组最前面
                  this.videoPlayArr.push(item);//轮播播放视频
              }
            })
          }
        }
      })
    },
    //指令获取终端附件
    orderAttachment(){
      const { alarmNo,alarmSignTerminalId,alarmSignTime,alarmSignFileNum,alarmSignNum } = this.locatePointShow;
      let timeTlag = false
      try {
        let currentTime = new Date().getTime()
        let sendAlarmSignTime = new Date(alarmSignTime).getTime();
        timeTlag = Math.floor((currentTime - sendAlarmSignTime)/1000/60)>5
      } catch (error) {}
      //产生报警超过5分钟，若无附件 则下发指令
      if(alarmNo&&alarmSignTerminalId&&alarmSignTime&&alarmSignFileNum&&timeTlag){
        //下发指令 ["报警号alarmNo", "设备号alarmSignTerminalId", "报警时间alarmSignTime", "报警序号alarmSignNum", "附件数alarmSignFileNum"],
          this.$yhsdp.attachmentRequest(this.detailData.terminalPhoneNo, alarmNo, alarmSignTerminalId, alarmSignTime, alarmSignNum,alarmSignFileNum)
          this.$message.warning('已下发指令获取，请稍后查看！')
      }else{
        this.$message.warning('暂无附件，已下发指令获取！')
      }
    },
    //获取详情
    getDetail () {
      let alarmNos = []
      let data = {
        alarmNo: this.detailData.alarmNo,
        system: this.system,
        terminalPhoneNo: this.detailData.terminalPhoneNo,
        alarmSignTime: this.detailData.alarmSignTime,
        alarmEndTime: this.detailData.alarmEndTime,
        id:this.detailData.id,//v3.0新增参数
        idStr:this.detailData.idStr
      }
      this.alarmID = [this.detailData.alarmNo]
      //报警处理数据
      queryAlarmDetail(data).then((res) => {
        if (res.code === 1000) {
          this.detailForm = res.data
          if (res.data.alarmDetailVoList.length > 0) {
            this.RowDetailFun(res.data.alarmDetailVoList[0]);//获取附件
          }
          this.$nextTick(() => {
            this.$refs.tableName.doLayout();

          })
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
  },
  mounted () {
    if (!this.isBatch) {
      this.getDetail()
      this.getAlarmTrace()
    } else {
      this.activeName = 'second';
    }
    this.getTemplateList()
  }
}
</script>
<style lang="scss" scoped>
/deep/.deal-form {
  .el-form-item {
    &:nth-of-type(1),
    &:nth-of-type(2) {
      width: 45%;
    }
    &:last-of-type {
      width: 100%;
      display: flex;
      .el-form-item__content {
        flex: 1;
      }
    }
  }
}
.alarm-dialog {
  .map-style {
    width: 100%;
    height: calc(45vh - 24px);
  }
  .null-tips {
    width: 100%;
    line-height: 16vh;
    text-align: center;
    color: #999;
  }
  .mediaStyle {
    width: 45%;
    height: 50%;
    display: inline-block;
    margin-right: 2%;
    img {
      width: 100%;
      height: 100%;
    }
    .video-player {
      width: 100%;
      height: 100%;
      border: 1px solid transparent;
      background: transparent;
    }
    /deep/.el-carousel {
      height: 100%;
      .el-carousel__container {
        height: 100%;
      }
    }
  }
  .title {
    margin-bottom: 6px;
  }
  .alarm-dia {
    // height: calc(79vh - 149px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    @include themify() {
      .fixed {
        flex: 1;
      }
      .scroll {
        overflow-y: auto;
      }
      .title {
        color: themed('n7');
        font-size: 14px;
        margin-bottom: 16px;
      }
      .header-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 6px;
        span {
          margin-right: 1vw;
        }
      }
      .information,
      .record,
      .deal {
        background-color: themed('n1');
      }
      .information {
        padding: 12px;
        margin-bottom: 16px;
        .head {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          color: themed('n8');
          font-size: 14px;
          margin-bottom: 16px;
          p:nth-of-type(1) {
            margin-bottom: 16px;
          }
          // p:nth-of-type(4n) {
          //   text-align: right;
          // }
        }
        .split-line {
          border-bottom: 1px solid;
          border-bottom-color: themed('n4');
          margin: 0 -12px 12px;
        }
      }
      .record {
        padding: 12px;
        margin-bottom: 16px;
      }
      .deal {
        padding: 16px;
      }
      .sign {
        color: themed('b4');
      }
      .pointer {
        cursor: pointer;
      }
    }
  }
  @include themify() {
    .alarmName {
      color: themed('b4');
      cursor: pointer;
    }
    .information {
      padding: 12px 0;
      margin-bottom: 6px;
      .head {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        color: themed('n8');
        font-size: 14px;
        margin-bottom: 16px;
        p:nth-of-type(1) {
          margin-bottom: 16px;
        }
        // p:nth-of-type(4n) {
        //   text-align: right;
        // }
      }
      .split-line {
        border-bottom: 1px solid;
        border-bottom-color: themed('n4');
        margin: 0 -12px 12px;
      }
    }
  }
}
</style>